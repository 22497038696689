textarea::placeholder {
    font-size: 13px; /* Change this value to your desired font size */
    color: #A0AEC0; /* Optional: Change the color if needed */
}
input::placeholder{
    font-size: 13px; /* Change this value to your desired font size */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
